import * as React from 'react';

// MUI Imports
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

// CSS Imports
import '../../../styles/landingPage.scss';

export default function ResumeHeader() {
  const handleDownload = () => {
    window.location.href = '/download_resume';
  };

  return (
    <AppBar position="sticky" className="sticky-header">
      <Toolbar>
        <Typography variant="h6" component="div">
          Work History
        </Typography>
        <Tooltip title="Download Resume" placement="bottom-start">
          <Button onClick={handleDownload}>
            <CloudDownloadIcon />
          </Button>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
}

import React from 'react';

// MUI Timeline Imports
import MuiTimeLineItem from '@mui/lab/TimelineItem';
import MuiTimelineConnector from '@mui/lab/TimelineConnector';
import MuiTimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineContent from '@mui/lab/TimelineContent';
import MuiTimelineSeparator from '@mui/lab/TimelineSeparator';

// MUI List Imports
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

// MUI Imports
import WorkIcon from '@mui/icons-material/Work';
import { Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

// Import CSS
import '../../../styles/landingPage.scss';

const TimelineItem = styled(MuiTimeLineItem)({
  // display: 'flex',
  // justifyContent: 'space-between',
  // alignItems: 'center',
  // flexDirection: 'row',
  // width: '100%',
  // margin: '0 auto',
});

const TimelineOppositeContent = styled(MuiTimelineOppositeContent)({
  // flex: 1,
  // display: 'flex',
  // justifyContent: 'flex-end',
  // alignItems: 'center',
  // paddingRight: '16px',
});

const TimelineSeparator = styled(MuiTimelineSeparator)({
  // flex: 0,
  // display: 'flex',
  // justifyContent: 'center',
  // alignItems: 'center',
});

const TimelineConnector = styled(MuiTimelineConnector)({
  minHeight: "130px",
});

const TimelineCard = ({ job, index, length }) => {
  const isLast = index === length - 1;

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot>
          <WorkIcon />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>

      <TimelineContent>
        <div key={index} className="timeline-text-container">
          <div aria-controls={`panel-${index}-content`} id={`panel-${index}-header`}>
            <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="flex-start">
              <Typography variant="h6" component="span">
                {job.position}
              </Typography>
              
              <Typography>
                {job.company}
              </Typography>

              <Typography>
                {job.date}
              </Typography>
            </Box>
          </div>

          <div className="hide-on-small">
            <List dense={true}>
              {job.bullets.map((bullet, i) => (
                <ListItem key={i}>
                  <ListItemText 
                    primary={bullet.title} 
                    secondary={bullet.body}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      </TimelineContent>
    </TimelineItem>
  );
};

export default TimelineCard;

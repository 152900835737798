import React from "react";

// Import Components
import PortfolioCarouselItem from "./PortfolioCarousel/PortfolioCarouselItem";
import PortfolioHeader from "./PortfolioCarousel/PortfolioHeader";

// MUI Imports
import Carousel from 'react-material-ui-carousel'

// Porfolio Import
import portfolioHistory from './PortfolioCarousel/portfolioHistory';

// Import CSS
import '../../styles/landingPage.scss';

export default function PortfolioSection() {
  return (
    <React.Fragment>
      <PortfolioHeader />
      <Carousel
        animation="slide"
        navButtonsAlwaysVisible
        autoPlay
        interval={12000}
        className="portfolio-carousel"
        sx={{ width: '100%', maxWidth: '800px', marginTop: '20px', marginBottom: '20px' }}
      >
          {portfolioHistory.map((item, index) => (
            <PortfolioCarouselItem key={index} item={item} sx={{ borderShadow: 'none' }} />
          ))}
      </Carousel>
    </React.Fragment>
  );
}
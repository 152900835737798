import React, { useState, useEffect, useRef } from 'react';
import ReactLoading from 'react-loading';
import { useParams } from 'react-router-dom';
import { findForms, getMetaData, uploadFiles } from '../services/openaiAPI';
import FormatResponse from './Shared/FormatResponse';
// import '../../assets/stylesheets/openAIChatbot.css';

function FormFinder() {
  const [prompt, setPrompt] = useState('');
  const [prompts, setPrompts] = useState([]);
  const [responses, setResponses] = useState([]);
  const [conversationTimestamp, setConversationTimestamp] = useState([]);
  const [metaData, setMetaData] = useState({});
  const { promptName } = useParams();
  const chatEndRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (promptName) {
      getMetaData(promptName)
        .then(data => {
          let prompts = [];
          let responses = [];
          for (let key in data) {
            if (data[key].previous_prompt) {
              if (typeof data[key].previous_prompt === 'object') {
                prompts.push(data[key].previous_prompt.text);
              } else {
                prompts.push(data[key].previous_prompt);
              }
            }
            if (data[key].previous_response) {
              if (typeof data[key].previous_response === 'object') {
                responses.push(data[key].previous_response.text);
              } else {
                responses.push(data[key].previous_response);
              }
            }
          }
          setPrompts(prompts);
          setResponses(responses);
          setConversationTimestamp(Object.keys(data));
          setMetaData(data);
          setConversationTimestamp(Object.keys(data));
        })
        .catch(error => console.error('Error:', error));
    }
  }, [promptName]);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [prompts, responses]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!prompt.trim()) {
      setErrorMessage('Please enter some text.');
      return;
    }

    setIsLoading(true);

    setErrorMessage('');
  
    let customPrompt = prompt;
  
    if (selectedFiles.length > 0) {
      uploadFiles(customPrompt, selectedFiles)
        .then((data) => {
          // Handle the server response
        })
        .catch((error) => {
          console.error('Error:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
        findForms(customPrompt)
        .then((text) => {
          const timestamp = new Date().toISOString().replace(/:/g, '-');
          setPrompts(oldPrompts => [...oldPrompts, customPrompt]);
          setResponses(oldResponses => [...oldResponses, text]);
          setMetaData(oldMetaData => {
            let newMetaData = {...oldMetaData};
            newMetaData[timestamp] = {previous_prompt: prompt, previous_response: text};
            setConversationTimestamp(Object.keys(newMetaData));
            return newMetaData;
          });
          setPrompt('');
        })
        .catch((error) => {
          console.error('Error:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  function formatTimestamp(timestamp) {
    if (!timestamp) {
      return 'N/A';
    }

    const formattedTimestamp = timestamp.replace(/(\d{4}-\d{2}-\d{2})[T-]?(\d{2})-(\d{2})-(\d{2})/, '$1T$2:$3:$4');
  
    const date = new Date(formattedTimestamp);

    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
  
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    const formattedDate = date.toLocaleString('en-US', options);
  
    return formattedDate;
  }

  return (
    <div className='chatContainer'>
      <div className='chatArea'>
        {prompts.map((prompt, index) => (
          <div className="messageContainer" key={index}>
            <label className='promptLabel'>Prompt</label>
            <p className='promptTimestamp'>{formatTimestamp(conversationTimestamp[index])}</p>
            <p className='promptBubble'>{prompt}</p> 
            {responses[index] && (
              <React.Fragment>
                <label className='responseLabel'>Response</label>
                <p className='responseTimestamp'>{formatTimestamp(conversationTimestamp[index])}</p>
                <div className='responseBubble'><FormatResponse item={responses[index]} /></div>
              </React.Fragment>
            )}
          </div>
        ))}
        <div ref={chatEndRef} />
      </div>
      
      <form onSubmit={handleSubmit}>
        <div className="inputWrapper">
          <div className="inputContainer">
            <div className="inputRow">
              <label>
                Search for a form/file or general information:
                <input type="text" value={prompt} onChange={e => setPrompt(e.target.value)} />
              </label>
            </div>
            <div className="inputRow">
              <button type="submit" disabled={isLoading}>
                {isLoading ? <ReactLoading type={"spin"} color={"#fff"} height={20} width={20} /> : 'Submit'}
              </button>
              {errorMessage && <p className="errorMessage">{errorMessage}</p>}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default FormFinder;

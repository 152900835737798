import React, { useState, useRef } from 'react';
import { generateText } from '../../../services/openaiAPI';

// MUI Imports
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';

function PaulBotChatForm({ setPrompts, setResponses, setConversationTimestamp }) {
  const [prompt, setPrompt] = useState('');
  const [starterPrompt, setStarterPrompt] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const submitButtonRef = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!prompt.trim()) {
      setErrorMessage('Please enter some text.');
      return;
    }

    setIsLoading(true);
    setErrorMessage('');

    let customPrompt = prompt;

    generateText(customPrompt)
      .then((text) => {
        const timestamp = new Date().toISOString().replace(/:/g, '-');
        setPrompts(oldPrompts => [...oldPrompts, customPrompt]);
        setResponses(oldResponses => [...oldResponses, text]);
        setConversationTimestamp(oldTimestamps => [...oldTimestamps, timestamp]);
        setPrompt('');
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDropdownChange = (e) => {
    const selectedPrompt = e.target.value;
    setStarterPrompt(selectedPrompt);
    setPrompt(selectedPrompt);
    setTimeout(() => {
      if (submitButtonRef.current) {
        submitButtonRef.current.click();
      }
    }, 0);
  };

  const starterPrompts = [
    'Who are you?',
    'What do you do for a living?',
    'What are your hobbies?',
    'What are your goals?',
    'What are you passionate about?'
  ];

  return (
    <Box sx={{ maxWidth: '800px', margin: '0 auto', marginTop: '10px', marginBottom: '50px', width: '-webkit-fill-available' }}>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          label="Chat with PaulBot"
          value={prompt}
          onChange={e => setPrompt(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              handleSubmit(e);
            }
          }}
          sx={{ marginBottom: "12px" }}
        />

        <FormControl fullWidth sx={{ marginBottom: "12px" }}>
          <InputLabel id="demo-simple-select-standard-label">Need help?</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={starterPrompt}
            onChange={handleDropdownChange}
            label="Need help?"
          >
            <MenuItem value=""><em>--Select a prompt--</em></MenuItem>
            {starterPrompts.map((prompt, index) => (
              <MenuItem key={index} value={prompt}>
                {prompt}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box>
          <LoadingButton
            endIcon={<SendIcon />}
            loading={isLoading}
            loadingPosition="end"
            ref={submitButtonRef}
            type="submit"
            variant="outlined"
            disabled={isLoading}
          >
            Send
          </LoadingButton>
          {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        </Box>
      </form>
    </Box>
  );
}

export default PaulBotChatForm;

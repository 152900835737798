import * as React from 'react';

// MUI Imports
import ResumeHeader from './ResumeTimeline/ResumeHeader';
import MuiTimeline from '@mui/lab/Timeline';
import TimelineCard from './ResumeTimeline/TimelineCard';
import { styled } from '@mui/material/styles';

// Work History Import
import workHistory from './ResumeTimeline/workHistory';

const Timeline = styled((props) => (
  <MuiTimeline {...props} />
))({
  margin: '0 auto',
  padding: 0,
  border: 0,
});

export default function ResumeSection() {
    return (
      <div>
        <ResumeHeader />
        <Timeline position="alternate">
          {workHistory.map((job, index) => (
              <TimelineCard key={index} job={job} index={index} length={workHistory.length}/>
          ))}
        </Timeline>
      </div>
    );
}

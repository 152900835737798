import React, { useState, useEffect, useRef } from "react";

// Components Imports
import PaulBotChatPrompts from '../landingpage/PaulBotAbout/PaulBotChatPrompts';
import PaulBotChatForm from '../landingpage/PaulBotAbout/PaulBotChatForm';
import PaulBotHeader from '../landingpage/PaulBotAbout/PaulBotHeader';
import { handleScroll } from '../../utils/scrollUtils';

// MUI Imports
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import { styled } from '@mui/material/styles';

const PaulBotChatAbout = styled("div")({
  maxWidth: "800px",
  margin: "0 auto",
  marginTop: "20px",
  display: 'flex',
  flexDirection: 'column',
  height: '90vh',
});

const PromptsContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  marginBottom: '20px',
	borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
}));

const MessageBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const ResponseContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  marginBottom: '10px',
  textAlign: 'left',
});

const dateTimeToday = new Date().toLocaleString();

function formatTimestamp(timestamp) {
  if (!timestamp) return 'N/A';

  const formattedTimestamp = timestamp.replace(/(\d{4}-\d{2}-\d{2})[T-]?(\d{2})-(\d{2})-(\d{2})/, '$1T$2:$3:$4');
  const date = new Date(formattedTimestamp);  

  if (isNaN(date.getTime())) return 'Invalid Date';

  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
  return date.toLocaleString('en-US', options);
}

export default function PaulBotChatSection() {
  const [prompts, setPrompts] = useState([]);
  const [responses, setResponses] = useState([]);
  const [conversationTimestamp, setConversationTimestamp] = useState([]);
  const promptsContainerRef = useRef(null);
  const isInitialRender = useRef(true);

  const scrollToBottom = () => {
    if (promptsContainerRef.current) {
      promptsContainerRef.current.scrollTop = promptsContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      scrollToBottom();
    }
  }, [prompts]);

  return (
    <Box>
      <PaulBotHeader />

      <PaulBotChatAbout>
        <PromptsContainer ref={promptsContainerRef}>

					<ResponseContainer>
						<MessageBox>
							<Typography variant="h6">PaulBot</Typography>
							<Typography variant="caption">{formatTimestamp(dateTimeToday)}</Typography>
							<Typography variant="body1">
								I'm a software engineer with a focus on full-stack development, 
								especially in Ruby on Rails, React, and AI prompt engineering. 
								I’m passionate about creating reliable, scalable solutions that make a difference. 
								From leading teams to building innovative systems, 
								I’ve enjoyed tackling complex problems and integrating AI to push projects further.
								My approach is simple: I aim to deliver quality and exceed expectations. 
								Whether it's kicking off a new project, optimizing an existing one, 
								or exploring what’s possible with AI, I’m here to help make it happen.
								Got questions? PaulBot can answer more about my work and background! 
								And if you’re looking for something specific, 
								you can contact the real me <Button size="small" href="#contact" onClick={(event) => handleScroll(event, '#contact')}>here</Button>.
							</Typography>
						</MessageBox>
					</ResponseContainer>

          <PaulBotChatPrompts 
            prompts={prompts} 
            responses={responses} 
            conversationTimestamp={conversationTimestamp} 
            formatTimestamp={formatTimestamp} 
          />
        </PromptsContainer>

        <PaulBotChatForm 
          setPrompts={setPrompts} 
          setResponses={setResponses} 
          setConversationTimestamp={setConversationTimestamp} 
        />
      </PaulBotChatAbout>
    </Box>
  );
}

const workHistory = [
  {
      date: 'Jun 2022 - Sep 2024',
      position: 'Full-Stack Software Engineer',
      company: 'Contently',
      bullets: [
        {
            title: 'Optimized Notifications Panel Performance:',
            body: 'Led the overhaul of a high-traffic notifications panel, addressing n+1 query issues and enhancing UI with modern React components. Achieved zero timeouts per Datadog metrics, boosting user engagement and page usage.'
        },
        {
            title: 'Full-Stack Feature Development:',
            body: 'Architected the notifications panel from backend to frontend, working closely with product and design teams. Also contributed to Contentlys text editor through pair programming, enhancing editor functionality and usability.'
        },
        {
            title: '360 Reviews and Actionable Feedback:',
            body: 'Participated in 360 reviews, providing valuable feedback to peers and managers, contributing to team growth and development.'
        },
        {
            title: 'Mentorship and Knowledge Sharing:',
            body: 'Organized weekly pair programming sessions with junior developers, fostering a culture of learning and skill-sharing.'
        },
        {
            title: 'User-Centric Updates with Data-Driven Insights:',
            body: ' Leveraged Datadog for user engagement analysis to guide UX enhancements, including predictive due dates in the text editor.'
        },
        {
            title: 'Enhanced Product-Engineering Communication:',
            body: 'Bridged alignment gaps between product and engineering by creating a dedicated Slack channel for real-time feedback and implementing individual QA environments, increasing team efficiency and collaboration.'
        }
      ]
  },
  {
      date: 'Dec 2020 - Mar 2022',
      position: 'Lead Full-Stack Software Engineer',
      company: 'Mobilize',
      bullets: [
          {
              title: 'Hiring and Onboarding Excellence:',
              body: 'Conducted interviews, hiring a team of developers and created an onboarding guide and 30-60-90 day goal plan to ensure new hire success.'
          },
          {
              title: 'Cross-Functional Collaboration:',
              body: 'Worked directly with the CEO, CTO, COO, and other teams, creating clear tickets and workflows with engineers to foster a collaborative environment.'
          },
          {
              title: 'Infrastructure & Backend Optimization:',
              body: 'Led backend development for production applications with Ruby on Rails and React, optimizing codebase performance and managing AWS resources (EC2, S3) to ensure efficient data handling.'
          },
          {
              title: 'Advanced Permissions System Design:',
              body: 'Developed a bespoke, flexible permissions system using Pundit, receiving positive feedback from stakeholders for its robustness and adaptability.'
          },
          {
              title: 'Community Impact:',
              body: 'Contributed to a project that facilitated the delivery of over 500,000 meals in Colorado, demonstrating technical expertise with a meaningful community impact.'
          }
        ]
    },
  {
    date: 'Mar 2022 - Jun 2022',
    position: 'Full-Stack Software Engineer',
    company: 'Contractor | The Ashley Group',
    bullets: [
        {
            title: 'Problem Solving & Code Quality:',
            body: 'Collaborated with engineering teams to implement solutions, debug complex issues, and perform in-depth code reviews, raising code standards and app performance.'
        },
        {
            title: 'Client-Specific Solutions:',
            body: 'Architected applications tailored to client needs, achieving project milestones with scalable, adaptable solutions.'
        },
        {
            title: 'Feature Enhancement:',
            body: 'Integrated new features based on client feedback, improving functionality and user experience.'
        },
        {
            title: 'Agile Development:',
            body: 'Used agile methods to ensure client-aligned, iterative design, testing, and development.'
        },
        {
            title: 'Project Delivery & Client Engagement:',
            body: 'Streamlined onboarding and support, enhancing delivery times and client satisfaction.'
        }
      ]
  },
  {
      date: 'Mar 2017 - Mar 2022',
      position: 'Full Stack Software Engineer / Project Manager',
      company: 'Freelance/Self Employed',
      bullets: [
          {
              title: 'Full-Stack Development Across Industries:',
              body: 'Delivered tailored frontend and backend solutions for diverse clients including Pokersprout, Retalent, Brooklyn Wine Exchange, and FindMine, enhancing client satisfaction across industries.'
          },
          {
              title: 'High-Impact Issue Resolution:',
              body: 'Spearheaded critical issue resolution for high-traffic applications like Brooklyn Wine Exchange, ensuring platform stability and scalability.'
          },
          {
              title: 'Collaborative Plugin Development:',
              body: 'Contributed to FindMines plugins for major brands such as Adidas and Torrid, expanding client functionality and user engagement.'
          },
          {
              title: 'Project Management and Communication:',
              body: 'Managed multiple projects using Jira and Trello, ensuring clear, timely communication with clients via Slack, and delivering organized project results.'
          },
          {
              title: 'Project Leadership:',
              body: 'Served as the primary point-of-contact for project management staff, leading software development initiatives, providing subject matter expertise, and ensuring successful project execution.'
          }
      ]
  }
];

export default workHistory;

import React from 'react';

// MUI Imports
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

// Components
import { ContactContainer } from '../RandomComponents';

export default function ContactSection() {
  return (
    <div>
      <Container maxWidth="sm" sx={{ margin: '12vh auto' }}>
        <ContactContainer />
      </Container>
    </div>
  );
}

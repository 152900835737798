import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import ReactOnRails from 'react-on-rails';
import axios from 'axios';
import "@hotwired/turbo-rails"
import "../controllers"

// Imports
import '@fortawesome/fontawesome-svg-core/styles.css';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { CustomDarkTheme } from '../components/landingpage/theme.jsx';
import '../styles/application.css';

// Components
import OpenAIChatbot from '../components/openAiChatbot.jsx';
import FormFinder from '../components/FormFinder.jsx';
import LandingPage from '../components/landingpage/landingPage.jsx';
import AmandasPage from '../components/AmandasPage.jsx';
import PaperWorker from '../components/PaperWorker.jsx';
import SignIn from '../components/SignIn.jsx';

// CSRF Setup
const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;

// Wrapper Components
const withProviders = (Component) => (props) => (
  <Router>
    <ThemeProvider theme={CustomDarkTheme}>
      <CssBaseline />
      <Component {...props} />
    </ThemeProvider>
  </Router>
);

// Register Components
const components = {
  OpenAIChatbot,
  FormFinder,
  PaperWorker,
  LandingPage,
  AmandasPage,
  SignIn
};

ReactOnRails.register(
  Object.fromEntries(
    Object.entries(components).map(([name, Component]) => [
      name,
      withProviders(Component)
    ])
  )
);

const portfolioHistory = [
  {
    title: 'Thryv',
    description: 'Thryv is an AI-powered fitness app that tailors weekly fitness and dietary plans based on your unique metrics and goals. By leveraging cutting-edge AI, Thryv creates routines that adapt to your progress, helping you achieve your fitness milestones with a plan that’s truly yours. Get ready to take control of your health journey with customized guidance and support every step of the way.',
    url: 'https://trythryv.com'
  },
  {
    title: 'R-Scale',
    description: 'R-Scale is a unique voting platform that empowers you to vote on anything you\'d like. Unlike traditional scales, R-Scale uses a range from -5 to +5, allowing you to express not just positive or neutral opinions, but also negative impacts when applicable. With this innovative approach, R-Scale gives you a voice that reflects the full spectrum of influence—positive or negative—on topics that matter to you.',
    url: 'https://r-scale.com'
  },
  {
    title: 'PaulBot',
    description: 'PaulBot is a specialized AI chatbot powered by OpenAI, here to answer any questions you may have about me—Paul. From my professional experience to personal insights, PaulBot is your go-to resource for learning more about who I am and what I do.',
    url: 'https://paulstorberg.com/chat'
  }
];

export default portfolioHistory;

// This file is for random components that don't fit anywhere else
import React from "react";

// MUI Imports
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";

export function ContactContainer() {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Contact Me
      </Typography>
      <form action="https://formspree.io/paul@storberg.net" method="POST" >
        <TextField fullWidth 
          multiline
          size="small"
          label="Name"
          name="name" 
          variant="standard"
          sx={{ marginBottom: '12px' }} />

        <TextField fullWidth 
          multiline
          size="small"
          label="Email"
          name="email"
          variant="standard"
          sx={{ marginBottom: '12px' }} />

        <TextField fullWidth
          multiline
          size="small"
          label="Questions..."
          name="questions"
          variant="standard" />

        <Button sx={{ marginTop: '12px' }}
          type="submit"
          variant="outlined"
        >
          Send Message
        </Button>
      </form>
    </div>
  );
}

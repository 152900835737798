import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { handleScroll } from '../../utils/scrollUtils';

import DarkHeroBackground from '../../images/HeroImages/dark-hero-background.png';
import LightHeroBackground from '../../images/HeroImages/light-hero-background.png';

export default function AboutSection() {
  return (
    <div>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          pt: 8,
          pb: 8,
          backgroundImage: `url(${DarkHeroBackground})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Container maxWidth="md">
          <Typography 
            variant="h2" 
            component="h1" 
            gutterBottom 
            sx={{ 
              fontWeight: 'bold', 
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)' 
            }}
          >
            Paul Storberg
          </Typography>

          <Typography variant="h5" color="text.secondary">
            Hello, I’m a software engineer passionate about building solutions that matter. From AI-driven innovations to full-stack apps, my focus is on quality, simplicity, and delivering real value.
          </Typography>
          <Button 
            variant="contained"
            size="large" 
            href="#paulbot"
            onClick={(event) => handleScroll(event, '#paulbot')}
            sx={{ mt: 4 }}
          >
            Chat with PaulBot
          </Button>
        </Container>
      </Box>
    </div>
  );
}

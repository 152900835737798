import React, { useState } from 'react';

// CSS Imports
import '../../styles/landingPage.scss';

// MUI Imports
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

// Components
import Sidebar from './Sidebar';

// Sections Import
import PaulBotChatSection from './PaulBotChatSection';
import ResumeSection from './ResumeSection';
import SkillsSection from './SkillsSection';
import PortfolioSection from './PortfolioSection';
import AboutSection from './AboutSection';
import ContactSection from './ContactSection';

const AppContainer = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  padding: 0,
});

const Content = styled(Box)({
  flexGrow: 1,
  marginLeft: '64px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});

const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1)
}));

const LandingPage = () => {
  const [darkMode, setDarkMode] = useState(false);
  const theme = useTheme();

  return (
    <AppContainer>
      <Sidebar />
      <Content className='content-container'>
        <Stack spacing={2}>
          {/* About Section */}
          <Section id="about" className='section-container'>
            <AboutSection />
          </Section>

          {/* PaulBot Section */}
          <Section id="paulbot" className='section-container'>
            <PaulBotChatSection />
          </Section>

          {/* Resume Section */}
          <Section id="resume" className='section-container'>
            <ResumeSection />
            {/* <SkillsSection /> */}
          </Section>

          {/* Portfolio Section */}
          <Section id="portfolio" className='section-container'>
            <PortfolioSection />
          </Section>

          {/* Contact Section */}
          <Section id="contact" className='section-container'>
            <ContactSection />
          </Section>
        </Stack>
      </Content>
    </AppContainer>
  );
};

export default LandingPage;

import * as React from 'react';

// MUI Imports
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

// CSS Imports
import '../../../styles/landingPage.scss';

export default function PortfolioHeader() {
  return (
    <AppBar position="sticky" className="sticky-header">
      <Toolbar>
        <Typography variant="h6" component="div">
          Personal Portfolio
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const PromptContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '10px',
  textAlign: 'right',
});

const ResponseContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  marginBottom: '10px',
  textAlign: 'left',
});

const MessageBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  // borderRadius: theme.shape.borderRadius,
  // backgroundColor: theme.palette.background.paper,
  // boxShadow: theme.shadows[1],
}));

function PaulBotChatPrompts({ prompts = [], responses = [], conversationTimestamp = [], formatTimestamp }) {
  return (
    <div>
      {prompts.map((prompt, index) => (
        <div key={index}>
          <PromptContainer>
            <MessageBox>
              <Typography variant="h6">User</Typography>
              <Typography variant="caption">{formatTimestamp(conversationTimestamp[index])}</Typography>
              <Typography variant="body1">{prompt}</Typography>
            </MessageBox>
          </PromptContainer>
          {responses[index] && (
            <ResponseContainer>
              <MessageBox>
                <Typography variant="h6">PaulBot</Typography>
                <Typography variant="caption">{formatTimestamp(conversationTimestamp[index])}</Typography>
                <Typography variant="body1">{responses[index]}</Typography>
              </MessageBox>
            </ResponseContainer>
          )}
        </div>
      ))}
    </div>
  );
}

export default PaulBotChatPrompts;

import { createTheme } from '@mui/material/styles';

const CustomDarkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#3b82f6',
      },
      secondary: {
        main: '#93c5fd',
      },
      background: {
        default: '#1a1e24',
      },
      text: {
        primary: '#d1d4db',
      },
    },
});

const CustomLightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
          main: '#3b82f6',
        },
        secondary: {
          main: '#1e40af',
        },
        background: {
          default: '#e5e9f0',
          paper: '#cbd5e1',
        },
        text: {
          primary: '#4a5568',
        },
    },
});

export { CustomDarkTheme, CustomLightTheme };

import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { generateText } from '../services/openaiAPI';

// MUI Imports
import LoadingButton from '@mui/lab/LoadingButton';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const PromptContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '10px',
  textAlign: 'right',
});

const PaulBotChatAbout = styled("div")({
  maxWidth: "800px",
  margin: "0 auto",
  marginTop: "20px",
  display: 'flex',
  flexDirection: 'column',
  height: '90vh',
});

const PromptsContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  marginBottom: '20px',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
}));

const ResponseContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  marginBottom: '10px',
  textAlign: 'left',
});

const MessageBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const FormContainer = styled(Box)({
  position: 'sticky',
  bottom: 0,
  padding: '10px'
});

const dateTimeToday = new Date().toLocaleString();

function OpenAIChatbot() {
  const [prompt, setPrompt] = useState('');
  const [starterPrompt, setStarterPrompt] = useState('');
  const [prompts, setPrompts] = useState([]);
  const [responses, setResponses] = useState([]);
  const [conversationTimestamp, setConversationTimestamp] = useState([]);
  const { promptName } = useParams();
  const chatEndRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);
  const submitButtonRef = useRef(null);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [prompts, responses]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!prompt.trim()) {
      setErrorMessage('Please enter some text.');
      return;
    }

    setIsLoading(true);
    setErrorMessage('');
  
    generateText(prompt)
      .then((text) => {
        const timestamp = new Date().toISOString().replace(/:/g, '-');
        setPrompts(oldPrompts => [...oldPrompts, prompt]);
        setResponses(oldResponses => [...oldResponses, text]);
        setConversationTimestamp(oldTimestamps => [...oldTimestamps, timestamp]);
        setPrompt('');
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDropdownChange = (e) => {
    const selectedPrompt = e.target.value;
    setStarterPrompt(selectedPrompt);
    setPrompt(selectedPrompt);
    setTimeout(() => {
      if (submitButtonRef.current) {
        submitButtonRef.current.click();
      }
    }, 0);
  };

  function formatTimestamp(timestamp) {
    if (!timestamp) return 'N/A';

    const formattedTimestamp = timestamp.replace(/(\d{4}-\d{2}-\d{2})[T-]?(\d{2})-(\d{2})-(\d{2})/, '$1T$2:$3:$4');
    const date = new Date(formattedTimestamp);

    if (isNaN(date.getTime())) return 'Invalid Date';

    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return date.toLocaleString('en-US', options);
  }

  const starterPrompts = [
    'Who are you?',
    'What do you do for a living?',
    'What are your hobbies?',
    'What are your goals?',
    'What are you passionate about?'
  ];

  return (
    <Box sx={{ minHeight: '100vh' }}>
      <AppBar position="sticky" className="sticky-header">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            PaulBot
          </Typography>
          <Button component={Link} to="/">Home</Button>
        </Toolbar>
      </AppBar>
      <PaulBotChatAbout>
        <PromptsContainer>
          <ResponseContainer>
            <MessageBox>
              <Typography variant="h6">PaulBot</Typography>
              <Typography variant="caption">{formatTimestamp(dateTimeToday)}</Typography>
              <Typography variant="body1">
                Hi there! I'm PaulBot, a chatbot powered by OpenAI ChatGPT. Im filling in for Paul while he's away. Feel free to ask me anything!
              </Typography>
            </MessageBox>
          </ResponseContainer>
          {prompts.map((prompt, index) => (
            <div key={index}>
              <PromptContainer>
                <MessageBox>
                  <Typography variant="h6">User</Typography>
                  <Typography variant="caption">{formatTimestamp(conversationTimestamp[index])}</Typography>
                  <Typography variant="body1">{prompt}</Typography>
                </MessageBox>
              </PromptContainer>
              {responses[index] && (
                <ResponseContainer>
                  <MessageBox>
                    <Typography variant="h6">PaulBot</Typography>
                    <Typography variant="caption">{formatTimestamp(conversationTimestamp[index])}</Typography>
                    <Typography variant="body1">{responses[index]}</Typography>
                  </MessageBox>
                </ResponseContainer>
              )}
            </div>
          ))}
          <div ref={chatEndRef} />
        </PromptsContainer>
      
        <FormContainer>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              label="Chat with PaulBot"
              value={prompt}
              onChange={e => setPrompt(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  handleSubmit(e);
                }
              }}
              sx={{ marginBottom: "12px" }}
            />

            <FormControl fullWidth sx={{ marginBottom: "12px" }}>
              <InputLabel id="demo-simple-select-standard-label">Need help?</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={starterPrompt}
                onChange={handleDropdownChange}
                label="Need help?"
              >
                <MenuItem value=""><em>--Select a prompt--</em></MenuItem>
                {starterPrompts.map((prompt, index) => (
                  <MenuItem key={index} value={prompt}>
                    {prompt}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box>
              <LoadingButton
                endIcon={<SendIcon />}
                loading={isLoading}
                loadingPosition="end"
                ref={submitButtonRef}
                type="submit"
                variant="outlined"
                disabled={isLoading}
              >
                Send
              </LoadingButton>
              {errorMessage && <Typography color="error">{errorMessage}</Typography>}
            </Box>
          </form>
        </FormContainer>
      </PaulBotChatAbout>
    </Box>
  );
}

export default OpenAIChatbot;

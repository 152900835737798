import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Import Images
import personalSiteImage from '../../../images/PortfolioImages/personal_site.png';
import rscaleImage from '../../../images/PortfolioImages/r_scale.png';
import thryvImage from '../../../images/PortfolioImages/thryv.png';

// Import CSS
import '../../../styles/landingPage.scss';

export default function PortfolioCarouselItem({ item }) {
  return (
    <Card>
      <CardMedia
        component="img"
        alt=""
        image={
          item.title === 'PaulBot' ? 
          personalSiteImage : item.title === 'R-Scale' ? 
          rscaleImage : thryvImage
        }
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {item.title}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {item.description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" href={item.url} target="_blank">Visit {item.title}</Button>
      </CardActions>
    </Card>
  );
}
